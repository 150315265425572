<template>
  <div>
    <v-list
      v-if="!loading"
      three-line
      class="pt-0"
    >
      <template v-for="(item, index) in itemsByDate">
        <DateListHeader
          v-if="item.date && item.date !== today"
          :key="index"
        >
          {{ item.date|i18n_dateToGerman }}
        </DateListHeader>
        <DateListHeader
          v-else-if="item.date && item.date === today"
          :key="index"
        >
          {{ $t('main.TODAY') }}
        </DateListHeader>
        <NewsItem
          v-else
          :key="index"
          :is-new="item.isNew"
          :time="item.time"
          :title="item.title"
          :country="item.country?.iso2 || ''"
          :text="item.text"
          :image="item.image"
          :tags="[...item.tags].sort((a, b) => a.order - b.order)"
          :newsType="item.newsType"
        />
      </template>
    </v-list>
  </div>
</template>

<script>
import moment from 'moment'
import NewsItem from '@/components/news/NewsItem';
import DateListHeader from '@/components/ui/DateListHeader';

export default {
  name: 'NewsItemsList',
  components: {
    DateListHeader,
    NewsItem
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    itemsByDate() {
      let itemsByDate = []
      let lastDate = null
      for (let item of this.items) {
        let newItem = {...item}
        newItem.time = moment(item.createdAt).format('HH:mm')

        const date = item.createdAt.split('T')[0];

        if (date !== lastDate) {
          itemsByDate.push({date: date})
          lastDate = date
        }
        itemsByDate.push(newItem)
      }
      return itemsByDate
    },
    today() {
      return moment().format('YYYY-MM-DD')
    }
  },
}
</script>
