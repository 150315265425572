<template>
  <div class="pa-0 ma-0">
    <v-row no-gutters style="height: 100vh; overflow: hidden">
      <v-col cols="4" class="event-display-left pb-0">
        <div class="event-display-left-scroll" ref="EventList">
          <div class="events-filter">
            <v-autocomplete
              v-model="country"
              :items="countries"
              item-text="nameShort"
              item-value="iso2"
              :clearable="true"
              :loading="loadingCountries"
              :label="$t('main.DESTINATION')"
              :placeholder="$t('main.SEARCH_FOR_COUNTRY')"
            />

            <v-autocomplete
              v-model="eventCategory"
              :items="eventsCategories"
              item-text="name"
              item-value="code"
              :clearable="true"
              :loading="loadingEventsCategories"
              :label="$t('main.TYPE')"
              :placeholder="$t('main.TYPE')"
              style="padding: 0"
            />

            <div class="checkbox-filters">
              <v-checkbox v-if="$hasPermission('a3m_core.DestinationManagerBosysBookings')" v-model="showLive" class="ma-1" :label="$t('main.SHOW_LIVE_EVENTS')"></v-checkbox>
              <div class="favorite-filter d-flex">
                <v-checkbox v-model="showFavoritesCountries" class="ma-1" :label="$t('main.SHOW_ONLY_FAVORITES')"></v-checkbox>
                <router-link :to="{name: 'organisation-countries-selection'}" style="height: 16px">
                  <v-icon class="mb-1" color="#113b71" style="left: -3px; font-size: 16px">edit</v-icon>
                </router-link>
              </div>
            </div>
          </div>
          <EventItemsList
            v-if="pinnedEventItems"
            :items="pinnedEventItems"
            :pinned="true"
            :allow-click="true"
            :timeAtCategory="true"
            :loading="loadingEvents"
            @select="openEventPage"
          />
          <EventItemsList
            :items="filteredListItems"
            :allow-click="true"
            :timeAtCategory="true"
            :loading="loadingEvents"
            @select="openEventPage"
          />
          <div class="ml-3" v-if="!filteredListItems.length && !loadingEvents">
            {{ $t('main.NO_ENTRIES_FOUND') }}
          </div>
          <v-pagination
            v-model="currentPage"
            :length="pageCount"
            :total-visible="5"
            style="margin-top: auto; padding-bottom: 10px"
            @input="onPageChange"
          ></v-pagination>
        </div>
      </v-col>
      <v-col class="pa-0 iframe-title">
        <div class="iframe-wrapper">
          <div v-if="event" class="iframe-title-content">
            <div class="d-flex">
              <v-spacer></v-spacer>

              <span v-if="!isPinned" class="pt-2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn @click="pin" text v-on="on" :loading="pinIsLoading"><v-icon>push_pin</v-icon></v-btn>
                  </template>
                  <span>{{ $t("main.PIN_GMEVENT_ACTION") }}</span>
                </v-tooltip>
              </span>

              <span v-if="isPinned" class="pt-2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn @click="unpin" text v-on="on" :loading="pinIsLoading"><v-icon>done</v-icon></v-btn>
                  </template>
                  <span>{{ $t("main.UNPIN_GMEVENT_ACTION") }}</span>
                </v-tooltip>
              </span>
            </div>
            <div class="event-url">Url: <a :href="event.staticPageUrl" target="_blank">{{ event.staticPageUrl }}</a></div>
            <div class="pl-2">
              <BosysDisplay :event="event" :bookingsEvent="bookingsEvent" :showDetails="false" v-if="$hasPermission('a3m_core.DestinationManagerBosysBookings') && event"/>
            </div>
          </div>

           <iframe
              v-if="event"
              :src="event.staticPageUrl"
              class="event-content"
              title="Event information"
            />

          <div
            v-if="!event"
            class="empty-icon"
          >
            <v-icon size="300" color="grey lighten-3">event</v-icon>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import EventService from '@/services/EventService';
import EventItemsList from "@/components/event/EventItemsList.vue";
import {mapActions, mapGetters, mapState} from "vuex";
import BosysDisplay from "../components/bosys/BosysDisplay.vue";

export default {
  name: "EventsView",
  components: {
    BosysDisplay,
    EventItemsList,
  },
  data() {
    return {
      event: null,
      country: null,
      eventCategory: null,

      showLive: false,
      showFavoritesCountries: false,

      currentPage: 1,
      itemsPerPage: 20,

      loadingEvents: false,
      loadingCountries: false,
      loadingEventsCategories: false,

      pinIsLoading: false,
      bookingsEvent: null,
    }
  },
  mounted() {
    this.loadingEvents = true
    this.fetchAllEvents({force: true}).then(() => {
      this.loadingEvents = false
    })
    this.loadingEventsCategories = false
    this.eventsCategoriesFetchAll().then(() => {
      this.loadingEventsCategories = false
    })
    this.doCountriesFetchAll()
  },
  computed: {
    ...mapState({
      countries: state => state.countriesStore.entries,
      eventsCategories: state => state.eventsCategoriesStore.entries,
      favoritesCountries: state => state.organisationCountrySelectionsStore.entries
    }),
    ...mapGetters({
      events: 'eventsStore/withIsUnread',
      liveEvents: 'eventsStore/withIsUnreadLive',
      pinnedEvents: 'eventsStore/withIsUnreadPinned',
      eventsPinnedGetById: 'eventsStore/getPinnedById',
    }),
    isPinned() {
      if (this.event) {
        let found = this.eventsPinnedGetById(this.event.id)
        if (found) {
          return true
        }
      }
      return false
    },
    pageCount() {
      if (!this.events || !Array.isArray(this.events)) {
        return 0;
      }
      return Math.ceil(this.dateListItems.length / this.itemsPerPage);
    },
    pinnedEventItems() {
      let newsEvents = [];

      this.pinnedEvents.forEach(eventObj => {
        let newsEvent = this.processEvent(eventObj);
        newsEvents.push(newsEvent);
      });

      return newsEvents
    },
    filteredListItems() {
      const dateItems = this.dateListItems;

      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;

      return dateItems.slice(start, end);
    },
    dateListItems() {
      let newsEvents = [];
      let events = this.showLive ? this.liveEvents : this.events;

      if (!events || !Array.isArray(events)) {
        events = [];
      }

      events.forEach(eventObj => {
        const event = eventObj;

        const matchesFavoritesCountry = (!this.favoritesCountries || !this.showFavoritesCountries) ||
            (event.event.country && this.favoritesCountries.some(country => country.country.id === event.event.country.id));
        const matchesCategory = !this.eventCategory || event.event.category.code === this.eventCategory;
        const matchesCountry = !this.country || (event.event.country && event.event.country.iso2 === this.country);

        if (matchesCategory && matchesCountry && matchesFavoritesCountry) {
          this.currentPage = 1
          let newsEvent = this.processEvent(eventObj);
          newsEvents.push(newsEvent);
        }
      });
      return newsEvents;
    },
  },
  methods: {
    ...mapActions({
      fetchAllEvents: 'eventsStore/fetchAll',
      countriesFetchAll: "countriesStore/fetchAll",
      eventsCategoriesFetchAll: 'eventsCategoriesStore/fetchAll',
      eventsPinnedPin: 'eventsLatestSignificantUpdatesPinnedStore/pin',
      eventsPinnedUnpin: 'eventsLatestSignificantUpdatesPinnedStore/unpin',
      bosysFetchAll: 'bosysStore/fetchAll',
    }),
    processEvent(eventObj) {
      const { id, event, isNew, travelersNow, travelersPotentiallyAffected } = eventObj;

      let newsEvent = {};

      let icon = EventService.getIconFileNameByCategorty(event.category.code, event.level);

      newsEvent.id = id;
      newsEvent.text = event.title;
      newsEvent.createdAt = event.significantUpdateAt;
      newsEvent.category = event.category.name;
      newsEvent.categoryIcon = icon;
      newsEvent.isNew = isNew;
      newsEvent.staticPageUrl = event.staticPageUrl;
      newsEvent.gmUniqueEventId = event.gmUniqueEventId;
      newsEvent.travelersNow = travelersNow;
      newsEvent.travelersPotentiallyAffected = travelersPotentiallyAffected;

      return newsEvent;
    },
    doCountriesFetchAll() {
      this.loadingCountries = true
      this.countriesFetchAll().then(() => {
        this.loadingCountries = false
      })
    },

    openEventPage(event) {
      this.event = event
      this.fetchBookings()
    },
    onPageChange() {
      this.$refs.EventList.scrollTo({
          top: 0,
          behavior: 'smooth'
      });
    },
    pin() {
      this.pinIsLoading = true
      this.eventsPinnedPin(this.event.id)
        .then(() => this.fetchAllEvents({force: true}))
        .then(() => this.pinIsLoading = false)
    },
    unpin() {
      this.pinIsLoading = true
      this.eventsPinnedUnpin(this.event.id)
        .then(() => this.fetchAllEvents({force: true}))
        .then(() => this.pinIsLoading = false)
    },
    fetchBookings(){
      this.bosysFetchAll({ variables: { gmUniqueEventId: this.event.gmUniqueEventId }, force: true })
        .then(response => {
          this.bookingsEvent = response;
        })
    }
  }
}
</script>

<style scoped>
  .event-display-left {
    background: #F7F9FC;
    z-index: 10;
  }

  .event-display-left-scroll {
    overflow: auto;
    padding-inline: 18px;
    scrollbar-width: thin;
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  .event-content {
    width: 100%;
    height: 100%;
    border: 0;
  }

  .empty-icon {
    height: 100%;
    display: flex;
    background: #f5f5f7;
    align-items: center;
    justify-content: center;
  }

  .events-filter {
    background: #FFFFFF;
    margin-top: 32px;
    border-radius: 10px;
    padding: 18px;
    margin-bottom: 16px;
  }

  .checkbox-filters {
    display: flex;
    gap: 15%;
  }

  .iframe-title {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f5f5f7;
    height: 100%;
  }

  .iframe-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 960px;
    width: 100%;
    height: 100%;
  }

  .iframe-title-content {
    background: #FFFFFF;
  }

  .event-url {
    margin-left: 10px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: 0.0071428571em;
  }

</style>