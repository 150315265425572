<template>
  <v-list-item
    :key="title"
    class="date-list-item"
  >
    <div class="image-container">
      <img
          v-if="image && newsType === 'DESTINATIONMANAGER'"
          class="news-image" :src="newsImage"
          width="80px"
          height="50px"
          alt="Destination Manager news image"/>
      <img
          v-else-if="country && newsType !== 'DESTINATIONMANAGER'"
          class="news-image"
          :src="countryFlagUrl"
          width="40px"
          height="20px"
          alt="Country flag"/>
    </div>

    <v-list-item-content>
      <v-list-item-title class="wrap-text">
        <slot name="category">
          <div class="category">
            {{ title }}
            <span v-if="time" class="item-time">{{ time }}</span>
          </div>
        </slot>
      </v-list-item-title>

      <v-list-item-subtitle class="news-text" :class="{ 'expanded': isExpanded }" ref="textContainer">
        <slot name="is-new">
          <v-chip v-if="isNew" x-small color="green" style="color: white;" class="item-chip">{{ $t("main.NEW_ICON_TEXT") }}</v-chip>
        </slot>
        <slot name="text">
          <span>{{ textClean }}</span>
        </slot>
      </v-list-item-subtitle>
      <div class="d-flex align-center justify-space-between">
        <v-chip-group column>
          <v-chip
            v-if="newsType in newsTypeMapping"
            :color="newsType === 'GENERIC' ? 'red' : 'green'"
            text-color="white">
            {{newsTypeMapping[newsType]}}
          </v-chip>
          <v-chip
              v-for="(tag, index) in tags"
              :key="index"
            >
              {{ tag.name }}
            </v-chip>
        </v-chip-group>
        <span class="read-more" v-if="isTextTruncated" @click="clicked">
          {{ isExpanded ? $t('main.SHOW_LESS') + '...' : $t('main.READ_MORE') + '...'}}
        </span>
      </div>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { backendMediaUrl } from '@/settings'

export default {
  name: 'NewsItem',
  props: {
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    isNew: {
      type: Boolean,
      default: false
    },
    time: {
      type: String,
      default: ''
    },
    tags: {
      type: Array,
      default: () => []
    },
    newsType: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    country: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isExpanded: false,
      containerWidth: 0,
      newsTypeMapping: {
        'GENERIC': this.$t('main.IMPORTANT_NEWS'),
        'DESTINATIONMANAGER': this.$t('main.DESTINATION_MANAGER'),
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.updateContainerWidth();
    });
  },
  computed: {
    newsImage() {
      return backendMediaUrl + '/' + this.image;
    },
    textClean() {
      // A version of the text without html, as required for the preview
      var div = document.createElement("div")
      div.innerHTML = this.text
      return div.textContent || div.innerText || ""
    },
    isTextTruncated() {
      let canvas = document.createElement('canvas');

      let ctx = canvas.getContext("2d");
      ctx.font = "14px Roboto";

      let width = ctx.measureText(this.textClean).width;

      width = width - 3 * this.containerWidth

      return width + ctx.measureText('...').width > -1
    },
    countryFlagUrl() {
      return 'https://global-monitoring.com/gm/data/model/al0/link_flag_small/'+ this.country +'.png'
    }
  },
  methods: {
    clicked() {
      this.isExpanded = !this.isExpanded;
    },
    updateContainerWidth() {
      this.containerWidth = this.$refs.textContainer.offsetWidth;
    }
  },
}
</script>

<style>
  .date-list-item.v-list-item {
    min-height: auto;
  }
</style>

<style scoped>

  .date-list-item {
    align-items: flex-start;
  }

  .wrap-text {
    white-space: normal;
  }

  .item-time {
    color: gray;
    float: right;
    font-size: 90%;
    font-weight: normal;
  }

  .item-chip {
    margin-right: 0.5rem;
  }

  .category{
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.2px;
    text-align: left;
  }

  .expanded {
    display: block !important;
  }

  .read-more {
    cursor: pointer;
    text-decoration: underline;
    font-size: 14px;
    user-select: none;
  }

  .image-container {
    padding: 17px 0;
    margin-right: 17px;
    width: 80px;
    display: flex;
    justify-content: flex-end;
  }

  .news-text {
    -webkit-line-clamp: 3 !important;
  }
</style>