<template>
  <div class="myjourney-main">
    <div class="myjourney-content">
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="10"
        :disable-sort="true"
        :options.sync="options"
        :server-items-length="totalCount"
        :footer-props="footerProps"
        :loading="loading"
        fixed-header
        class="myjourney-table"
      >
        <template v-slot:item.link="{ item }">
          <span v-if="!item.isActive">{{ $t('ui.Expired') }}</span>
          <span v-else-if="item.isManuallyDisabled">
            {{ $t('main.DEACTIVATED') }}
          </span>
          <v-btn
            v-else
            color="primary"
            :href="item.link"
            small
            rounded
            class="myjourney-button"
            target="_blank"
          >
            {{ $t('main.OPEN_ACTION') }}
          </v-btn>
        </template>
        <template v-slot:item.edit="{ item }">
          <v-icon
            class="mr-2"
            color="primary"
            @click="openEditDialog(item)"
          >
            edit
          </v-icon>
      </template>
        <template v-slot:item.restrictionType="{ item }">
          <span v-if="item.restrictionType == 'TOURISM'">{{ $t('main.PRIVATE_TRAVEL') }}</span>
          <span v-if="item.restrictionType == 'BUSINESS'">{{ $t('main.BUSINESS_TRAVEL') }}</span>
        </template>
        <template v-slot:item.dateRange="{ item }">
          <div style="min-width: 185px">
            {{ item.dateStart | i18n_dateToGerman }} - {{ item.dateEnd | i18n_dateToGerman }}
          </div>
        </template>
        <template v-slot:item.createdAt="{ item }">
          <span class="text-no-wrap">{{ item.createdAt | i18n_dateToGerman }}</span>
        </template>
      </v-data-table>

      <v-dialog
        v-model="editDialog"
        max-width="500px"
      >
      <v-card class="dialog-edit">
        <v-card-title>{{ $t('main.EDIT_DETAILS') }}</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="editedItem.email"
                  :label="$t('main.EMAIL_ADDRESS')"
                  outlined
                  :rules="emailRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0 pb-0">
                <v-textarea
                  v-model="editedItem.internalNote"
                  :label="$t('main.MYTRIP_TABLE_NOTE')"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-checkbox class="mt-0" v-model="editedItem.isManuallyDisabled" :label="$t('main.CHANGING_RIDE_ACCESS')"></v-checkbox>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeEditDialog">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="saveEdit">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

      <v-alert
        class="mt-4"
        dense
        type="info"
        text
      >
        {{ $t('main.MYTRIP_HISTORY_DISCLAIMER') }}
      </v-alert>
    </div>
  </div>
</template>

<script>
import { debounce } from "debounce"

export default {
  name: 'MyJourneyHistory',
  data() {
    return {
      loading: false,
      search: '',
      totalCount: null,
      options: {},
      footerProps: {
        itemsPerPageOptions: [5, 10, 20, 50],
        itemsPerPageText: this.$t('main.ITEMS_PER_PAGE')
      },
      headers: [
        { text: this.$t('main.MYTRIP_TABLE_DEPARTURE'), value: "departure" },
        { text: this.$t('main.MYTRIP_TABLE_TRANSIT'), value: "transit" },
        { text: this.$t('main.MYTRIP_TABLE_DESTINATION'), value: "destination" },
        { text: this.$t('main.CITIZENSHIPS'), value: "citizenships" },
        { text: this.$t('main.MYTRIP_TABLE_TRAVEL_TYPE'), value: "restrictionType" },
        { text: this.$t('main.MYTRIP_TABLE_DATES'), value: "dateRange" },
        { text: this.$t('main.EMAIL_ADDRESS'), value: "email" },
        { text: this.$t('main.MYTRIP_TABLE_NOTE'), value: "internalNote" },
        { text: this.$t('main.MYTRIP_TABLE_CREATED'), value: "createdAt" },
        { text: this.$t('main.STATUS'), value: "link" },
        { text: 'Edit', value: "edit" },
      ],
      items: [],
      editDialog: false,
      editedItem: {},
      emailRules: [
          v => !v || /.+@.+\..+/.test(v) || this.$t('main.EMAIL_ADDRESS_IS_INVALID'),
        ]
    }
  },
  created() {
    /* must be in a per-component base, or we get problems with recreated instances*/
    this.debounceSearch = debounce((search) => {
      // Multiple searches might happen, even with debounce
      // We only use the result of this query if the request still matches
      // the current search text in the autocomplete input
      if (search == this.search) {
        this.getMyJourneyList()
      }
    }, 500)
  },
  mounted() {
    this.$vuetify.lang.current = 'de'
    this.getMyJourneyList()
  },
  methods: {
    getMyJourneyList() {
      let { page, itemsPerPage } = this.options
      const offset = itemsPerPage * (page - 1)

      this.loading = true
      this.$myJourneyRepository.getMyJourneyList(itemsPerPage, offset, this.search)
        .then((result) => {
          this.loading = false
          this.updateItems(result)
        })
        .catch(() => {
          this.loading = false
        })
    },
    updateItems(result) {
      this.totalCount = result.totalCount
      this.items.splice(0) // clear the array and keep it reactive

      for (let item of result.results) {
        let citizenshipList = []
        for (let cit of item.citizenships) {
          citizenshipList.push(cit.citizenshipCountry.nameShort)
        }
        let citizenshipsName = citizenshipList.join(", ")

        let destinationList = []
        for (let dest of item.destinations) {
            destinationList.push(dest.destinationName)
        }
        let destinationName = destinationList.join(", ")

        this.items.push({
          id: item.id,
          departure: item.departureName,
          destination: destinationName,
          transit: item.transitName,
          citizenships: citizenshipsName,
          restrictionLevel: item.restrictionLevel,
          restrictionType: item.restrictionType,
          dateStart: item.dateStart,
          dateEnd: item.dateEnd,
          language: item.language,
          isActive: item.isActive,
          isManuallyDisabled: item.isManuallyDisabled,
          email: item.email,
          createdAt: item.createdAt,
          internalNote: item.internalNote,
          link: item.link
        })
      }
    },
    openEditDialog(item) {
      this.editedItem = { ...item };
      this.editDialog = true;
    },
    closeEditDialog() {
      this.editDialog = false;
    },
    saveEdit() {
      this.updateEmailNote(this.editedItem);
      this.closeEditDialog();
    },
    updateEmailNote(item) {
      this.loading = true
      this.$myJourneyRepository.updateMyJourneyNoteEmail(item.id, item.email, item.internalNote, item.isManuallyDisabled)
          .then((result) => {
            this.loading = false
            const updatedItemIndex = this.items.findIndex(el => el.id === result.id);
            if (updatedItemIndex !== -1) {
              this.items[updatedItemIndex].email = item.email;
              this.items[updatedItemIndex].internalNote = item.internalNote;
              this.items[updatedItemIndex].isManuallyDisabled = item.isManuallyDisabled;
            }
          })
          .catch(() => {
            this.loading = false
          })
    }
  },
  watch: {
    options: {
      handler () {
        this.getMyJourneyList()
      },
      deep: true,
    },
    search() {
      this.debounceSearch(this.search)
    },
  },
}
</script>

<style scoped>
  .myjourney-main {
    display: flex;
    padding: 2rem 2rem;
    justify-content: center;
    background-color: #f7f7f7;
    height: 100vh;
    overflow: hidden;
  }

  .myjourney-content {
    width: 100%;
  }

  .myjourney-list {
    padding: 32px;
    background-color: white;
  }

  .myjourney-button {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    letter-spacing: normal;
    text-transform: unset;
  }

  .myjourney-table {
    border-radius: 10px;
    box-shadow: 0 4px 1px -2px rgba(0, 0, 0, 0.3);
  }

  .myjourney-table /deep/ .v-data-table__wrapper{
    border-radius: 10px 10px 0 0;
    height: auto;
    max-height: 85vh;
  }

  .dialog-edit{
    border-radius: 10px !important;
  }

</style>