<template>
  <div class="main-view">
    <div class="map-control" v-if="$hasPermission('a3m_core.DestinationManagerBosysBookings') || countryIds.length">
      <v-list class="map-control-list" shaped>
        <v-list-item-group color="primary" mandatory>
          <v-list-item>
            <v-list-item-title>{{ $t("main.ALL_EVENTS") }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-model="showFavoriteEvents" v-if="countryIds.length">
            <v-list-item-title>{{ $t("main.EVENTS_IN_FAVORITE_COUNTRIES") }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-model="showLiveEvents" v-if="$hasPermission('a3m_core.DestinationManagerBosysBookings')">
            <v-list-item-title>{{ $t('main.MAP_LIVE_EVENTS') }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </div>
    <EventsWorldMap
      class="main-map"
      :countries-geojson="geoJson"
      :showLiveEvents="showLiveEvents"
      :showFavoriteEvents="showFavoriteEvents"
    />
  </div>
</template>

<script>
import countriesGeoJson from '../assets/world_30sep2021.json'
import EventsWorldMap from "@/components/map/EventsWorldMap"
import {mapGetters} from "vuex";


export default {
  name: 'EventsMapView',
  components: {
    EventsWorldMap,
  },
  computed: {
    ...mapGetters({
      countryIds: 'organisationCountrySelectionsStore/countryIds',
    }),
    geoJson() {
      return countriesGeoJson
    }
  },
  data() {
    return {
      showLiveEvents: false,
      showFavoriteEvents: false
    }
  }

}
</script>

<style scoped>
  .main-view {
    margin: auto;
  }

  .main-map {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .map-control {
    width: fit-content;
    min-width: 200px;
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 100;
  }

  .map-control-list {
    border-radius: 10px !important;
  }
</style>
