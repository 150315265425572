<template>
  <l-map
    ref="map"
    :zoom="zoomValue"
    :center="centerPositionValue"
    :options="mapOptions"
    class="mainMap"
    style="z-index: 0;"
  >
    <v-progress-linear indeterminate class="map-loading" v-if="loading"></v-progress-linear>
    <l-control-zoom position="bottomright"  ></l-control-zoom>
    <l-tile-layer
      :url="mapUrl"
      :attribution="attribution"
    />
    <l-geo-json
      ref="geoJsonWorld"
      :geojson="geoJsonWorld"
      :optionsStyle="geoJsonStyle"
    />
    <l-marker
      v-for="(marker, index) in markers"
      :key="index"
      :lat-lng="marker.latLng"
      :icon="marker.icon"
      @click="onMarkerClick(marker)">
    </l-marker>
    <EventDialog :show="showEventDialog" :event="dialogEvent" @close="closeDialog"/>
  </l-map>
</template>

<script>
import { latLng } from "leaflet";
import L from 'leaflet';
import { LMap, LTileLayer, LGeoJson, LControlZoom, LMarker } from 'vue2-leaflet';
import 'leaflet.pattern'
import EventService from '@/services/EventService';
import {mapActions, mapGetters, mapState} from "vuex";
import EventDialog from "../event/EventDialog.vue";

export default {
  name: 'EventsWorldMap',
  components: {
    EventDialog,
    LMap,
    LTileLayer,
    LGeoJson,
    LControlZoom,
    LMarker
  },
  props: {
    countriesGeojson: {},
    showLiveEvents: {
      type: Boolean,
      default: false
    },
    showFavoriteEvents: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      zoomValue: 3,
      showEventDialog: false,
      dialogEvent: null,
      centerPositionValue: latLng(30.41322, 0),
      attribution:
        '&copy; <a href="http://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors',

      geoJsonStyle: {
        fillOpacity: 0,
        weight: 0
      },
      mapOptions: {
        zoomControl: false
      },

      mapEvents: [],
      mapLiveEvents: [],

      loading: false
    }
  },
  mounted() {
    this.loading = true
    this.fetchAllEvents().then((result) => {
      if (result) {
        this.mapEvents = result
        this.mapLiveEvents = result.filter(event => event.live === true)
      }
      this.loading = false
    })
  },
  computed: {
    ...mapState({
      language: state => state.uiStore.language
    }),
    ...mapGetters({
      countryIds: 'organisationCountrySelectionsStore/countryIds',
    }),
    events() {
      if (this.showLiveEvents) {
         return this.processEvents(this.mapLiveEvents);
      } else if (this.showFavoriteEvents) {
        return this.processEvents(this.mapEvents.filter(item => !this.countryIds.length || this.countryIds.includes(item.event.country.id)));
      }

      return this.processEvents(this.mapEvents);
    },
    markers() {
       return this.events.map(event => {
          let iconUrl = event.icon;

          const marker = {
            latLng: [event.latitude, event.longitude],
            event: event
          };

          if (iconUrl) {
            marker.icon = L.icon({ iconUrl: iconUrl, iconSize: [32, 32] });
          } else {
            iconUrl = require(`@/assets/default-category-icon.png`)
            marker.icon = L.icon({ iconUrl: iconUrl, iconSize: [100, 60] });
          }

          return marker;
       });
    },
    mapUrl() {
      // might be a good idea to make this i18n friendly
      return 'https://b.tile.openstreetmap.de/{z}/{x}/{y}.png'
    },
    geoJsonWorld() {
      return this.countriesGeojson
    },
  },
  methods: {
     ...mapActions({
      fetchAllEvents: 'mapEventsStore/fetchAll'
    }),
     processEvents(mapEvents) {
       return mapEvents.map(({ id, event, travelersNow, travelersPotentiallyAffected }) => {
        const icon = EventService.getIconFileNameByCategorty(event.category.code, event.level);

        return {
          id,
          text: event.title,
          category: event.category.name,
          icon: icon,
          staticPageUrl: event.staticPageUrl,
          gmUniqueEventId: event.gmUniqueEventId,
          travelersNow,
          travelersPotentiallyAffected,
          latitude: event.latitude,
          longitude: event.longitude,
        };
      });
     },

    onMarkerClick(marker) {
       this.dialogEvent = marker.event
       this.showEventDialog = true;
    },
    closeDialog() {
      this.showEventDialog = false;
    }
  },
  watch: {
    centerPositionValue: {
      immediate: true,
      handler(newVal) {
        this.centerPositionValue = newVal
      },
    },
  }
}
</script>

<style scoped>
  .map-loading {
    z-index: 1000;
    margin-top: 1px;
  }
</style>
