<template>
  <div class="news-view">
    <div class="news-container">
    <div style="min-width: 260px;">
      <v-list shaped class="mb-5" style="border-radius: 10px">
        <v-list-item-group color="primary" mandatory v-model="newsType">
          <v-list-item
            v-for="item in newsButtons"
            :key="item.value"
            :value="item.value"
          >
            <v-list-item-icon>
              <Svg-icon :type="item.iconType" :path="item.iconPath"></Svg-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <div class="filter-panel">
         <v-autocomplete
              v-if="newsType !== 'DESTINATIONMANAGER'"
              v-model="country"
              :items="countries"
              item-text="nameShort"
              item-value="nameShort"
              :clearable="true"
              :loading="loadingCountries"
              :label="$t('main.DESTINATION')"
              :placeholder="$t('main.SEARCH_FOR_COUNTRY')"
            />
        <div class="favorite-filter d-flex" v-if="newsType !== 'DESTINATIONMANAGER'">
          <v-checkbox v-model="showFavoritesCountries" class="ma-0" :label="$t('main.SHOW_ONLY_FAVORITES')"></v-checkbox>
          <router-link :to="{name: 'organisation-countries-selection'}" style="height: 16px">
            <v-icon class="mb-1" color="#113b71" style="left: 1px; top: -3px;font-size: 16px">edit</v-icon>
          </router-link>
        </div>

        <v-skeleton-loader
            v-if="loadingTags"
            type="article"
          />
        <v-chip-group class="filter-chips" v-if="!loadingTags" v-model="filterTag" color="primary" mandatory>
         <v-chip
            v-for="(tag, index) in tags"
            :key="index"
            :value="tag"
            outlined
          >
            {{ tag }}
          </v-chip>
        </v-chip-group>
      </div>
    </div>

    <div class="news-list" ref="NewsList">
      <v-skeleton-loader
        v-if="loading"
        type="article, article, article, article, article, article"
        />
      <NewsItemsList
        :items="filteredListItems"
        :loading="loading"
      />
      <v-pagination
          v-model="currentPage"
          :length="pageCount"
          :total-visible="5"
          style="margin-top: auto;"
          @input="onPageChange"
      ></v-pagination>
    </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import NewsItemsList from '@/components/news/NewsItemsList';
import { mdilAccount } from '@mdi/light-js';
import { mdiMap } from '@mdi/js';
import SvgIcon from "@jamescoyle/vue-icon";

export default {
  name: 'NewsView',
  components: {
    SvgIcon,
    NewsItemsList
  },
  data() {
    return {
      accountIcon: mdilAccount,
      mapIcon: mdiMap,

      newsType: 'ALL',

      newsButtons: [
        {value: 'ALL', title: this.$t('main.ALL_NEWS'), iconType: 'mdi', iconPath: mdiMap},
        {value: 'GENERIC', title: this.$t('main.IMPORTANT_NEWS'), iconType: 'mdi', iconPath: mdilAccount},
        {value: 'DESTINATIONMANAGER', title: this.$t('main.DESTINATION_MANAGER'), iconType: 'mdi', iconPath: mdilAccount},
      ],

      loading: true,
      loadingCountries: false,
      loadingTags: false,

      country: null,

      showFavoritesCountries: false,

      currentPage: 1,
      itemsPerPage: 50,

      allNewsText: this.$t('main.ALL_NEWS'),

      filterTag: null,
      tags: [this.$t('main.ALL_NEWS')]
    }
  },
  mounted() {
    this.loading = true
    this.newsFetchAll({force: true}).then(() => {
      this.loading = false;
    })

    this.loadingTags = true
    this.newsTagsFetchAll().then((result) => {
      this.tags = this.tags.concat(result.map(item => item.name))
      this.loadingTags = false
    })

    this.loadingCountries = true
    this.countriesFetchAll().then(() => {
      this.loadingCountries = false
    })
  },
  computed: {
    ...mapGetters({
      news: 'newsStore/newsWithIsUnread',
    }),
    ...mapState({
      countries: state => state.countriesStore.entries,
      favoritesCountries: state => state.organisationCountrySelectionsStore.entries,
      lastNewsVisitDate: state => state.uiStore.lastNewsVisitDate
    }),
    pageCount() {
      if (!this.news || !Array.isArray(this.news)) {
        return 0;
      }
      return Math.ceil(this.newsItems.length / this.itemsPerPage);
    },
    filteredListItems() {
      const news = this.newsItems;

      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;

      return news.slice(start, end);
    },
    newsItems () {
      return this.news.filter(item => {
        this.currentPage = 1
        const matchesFavoritesCountry = (!this.favoritesCountries || !this.showFavoritesCountries) ||
            (item.country && this.favoritesCountries.some(country => country.country.id === item.country.id));
        const matchesType = this.newsType === 'ALL' || item.newsType === this.newsType;
        const matchesCountry = !this.country || (item.country && item.country.nameShort === this.country);
        const matchesTags = (!this.filterTag || this.filterTag === this.allNewsText) || (item.tags && item.tags.some(tag => tag.name === this.filterTag));

        return matchesType && matchesCountry && matchesFavoritesCountry && matchesTags;
      });
    }
  },
  methods: {
    ...mapActions({
      countriesFetchAll: "countriesStore/fetchAll",
      newsFetchAll: "newsStore/fetchAll",
      newsTagsFetchAll: "newsTagsStore/fetchAll"
    }),
    onPageChange() {
      this.$refs.NewsList.scrollTo({
          top: 0,
          behavior: 'smooth'
      });
    },
  },
}
</script>

<style scoped>

  .news-view {
    padding-inline: 1.5%;
    padding-top: 32px;
    background: #F7F9FC;
    height: 100vh;
    overflow: hidden;
  }

  .news-list {
    width: 100%;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 18px;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
  }

  .filter-panel {
    background: #FFFFFF;
    height: fit-content;
    padding: 18px;
    border-radius: 10px;
  }

  .filter-chips /deep/ .v-slide-group__content {
    flex-direction: column;
  }

  .v-chip {
    width: fit-content;
  }

  .news-container {
    width: 83%;
    display: flex;
    gap: 1%;
    overflow: auto;
    height: 100%;
  }

</style>